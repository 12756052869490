import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Modal from "../../components/modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ExcelIcon } from "../../assets/tabler-icons/excelIcon";
import { base_url } from "../../data/consts";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Loader } from "rsuite";

function Doctor() {
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const [data, setData] = useState();
  const [editAgent, setEditAgent] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const [ArchiveKey, setArchiveKey] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [image, setImage] = useState(false);
  const [agent, setAgent] = useState(false);
  const changeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const source = e.target.files[0];
      const objectUrl = URL.createObjectURL(source);
      setImageSrc(objectUrl);
      setImage(e.target.files[0]);
    }
  };
  const deleteImage = () => {
    setImage(false);
    setImageSrc(false);
  };
  useEffect(() => {
    getAgents();
  }, []);
  const getAgents = async () => {
    try {
      const select = await axios.get(base_url + "select_agents.php");
      setData(select?.data?.message?.filter((item) => item?.archive == 0));
      setData(select?.data?.message?.filter((item) => item?.archive == 0));
    } catch (e) {
      setData([]);
      setOriginalData([]);
    }
  };
  const disable_enable_agent = async () => {
    const disableAgent = await axios.post(base_url + "edit_able.php", {
      agent_id: agent?.item?.agent_id,
      disable: parseInt(agent?.item?.disable) ? 0 : 1,
    });
    if (disableAgent.data.status == "success") {
      toast.success(
        " تم " +
          (parseInt(agent?.item?.disable) ? " السماح بعمل" : " إيقاف عمل ") +
          " الوكيل"
      );
      await getAgents();
      setAgent(false);
    } else {
      toast.error("فشلت العملية");
    }
  };
  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const editDoctor = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", image);
    const uploadImage = await axios.post(
      "https://baseeta-form.com/basita/image_uplouder.php",
      formData
    );
    const data = {
      ...editAgent,
      image: uploadImage?.data ? uploadImage?.data : editAgent?.image,
    };
    await axios
      .post("https://baseeta-form.com/basita/edit_agent.php", data)
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success("تم تعديل الوكيل بنجاح");
          setEditAgent(null);
          setImage(null);
          setImageSrc(null);
          getAgents();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };
  const ArchiveDoctor = async () => {
    setLoading(true);
    const disableAgent = await axios.post(base_url + "edit_archive.php", {
      agent_id: ArchiveKey?.agent_id,
      archive: parseInt(ArchiveKey?.item?.archive) ? 0 : 1,
    });
    if (disableAgent.data.status == "success") {
      toast.success("تم الإضافة إلى الأرشيف بنجاح");
      await getAgents();
      setArchiveKey(false);

      setAgent(false);
    } else {
      toast.error("فشلت العملية");
    }
    setLoading(true);
  };

  const ArchiveManyDoctor = async () => {
    setLoading(true);
    choosedDocs.map(async (item) => {
      const disableAgent = await axios.post(base_url + "edit_archive.php", {
        agent_id: item?.agent_id,
        archive: parseInt(ArchiveKey?.item?.archive) ? 0 : 1,
      });
      if (disableAgent.data.status == "success") {
        toast.success("تم الإضافة إلى الأرشيف بنجاح");
        await getAgents();
        setArchiveKey(false);

        setAgent(false);
      } else {
        toast.error("فشلت العملية");
      }
    });

    setLoading(false);
  };

  const headers = [
    {
      label: language == "ar" ? "تحديد" : "Select",
      type: "children",
      children: ({ row }) => {
        return (
          <input
            checked={row?.checked}
            type="checkbox"
            name=""
            id=""
            onClick={() => {
              row.choosed = !row?.choosed;
              if (row?.choosed) choosedDocs.push(row);
              else
                choosedDocs = [
                  ...choosedDocs.filter(
                    (item) => item?.login_id != row.login_id
                  ),
                ];
              setChoosedDocs([...choosedDocs]);
            }}
          />
        );
      },
    },
    {
      label: language == "ar" ? "الرقم التعريفي للوكيل" : "ID",
      dataIndex: "login_id",
    },
    {
      label: language == "ar" ? "إسم الوكيل" : "Agent Name",
      dataIndex: "name",
    },
    {
      label: language == "ar" ? "شعار الوكيل" : "Agent Image",
      type: "children",
      children: ({ row }) => {
        return (
          <span>
            <img src={row.image} alt="" />
          </span>
        );
      },
    },
    {
      label:
        language == "ar"
          ? "بريد الكتروني لاستقبال الاشعارات"
          : "Notification Email",
      dataIndex: "email",
    },
    {
      label: language == "ar" ? "كلمة سر الوكيل" : "Password",
      dataIndex: "password",
    },
    {
      label: language == "ar" ? "رقم الهاتف" : "Mobile",
      dataIndex: "phone",
    },
    {
      label: language == "ar" ? "تحديد" : "Select",
      type: "children",
      children: ({ row }) => {
        return (
          <span>
            {!parseInt(row?.disable) ? (
              <button className="btn btn-success" disabled>
                {language == "ar" ? " متاح للعمل" : "Working"}
              </button>
            ) : (
              <button
                className="btn btn-danger"
                style={{ whiteSpace: "nowrap" }}
                disabled
              >
                {language == "ar" ? " متوقف عن العمل" : "Stopped"}
              </button>
            )}
          </span>
        );
      },
    },
    {
      label: language == "ar" ? "أوامر" : "Actions",
      type: "actions",
      actions: [
        {
          label:
            language == "ar" ? "إيقاف / السماح - للوكيل" : "Stop/Allow Agent",
          action: setAgent,
        },
        {
          label: language == "ar" ? "فورم العميل" : "Customer From",
          action: ({ item }) => {
            return navigate("/doctor/form?doctor_id=" + item?.agent_id);
          },
        },

        {
          label: language == "ar" ? "تعديل الوكيل" : "Edit Agent",
          action: ({ item }) => setEditAgent(item),
        },
        {
          label: language == "ar" ? "أرشفة" : "Archive Doctor",
          action: ({ item }) => setArchiveKey(item),
        },
      ],
    },
    {
      label: language == "ar" ? "رؤية الفواتير" : "Show Invoices",
      type: "children",
      children: ({ row }) => {
        return (
          <span>
            {!parseInt(row?.show_fawaterek) ? (
              <button
                className="btn btn-success"
                onClick={async () => {
                  const disableAgent = await axios.post(
                    base_url + "edit_user_show_fatoorah.php",
                    {
                      agent_id: row?.agent_id,
                      show_fawaterek: parseInt(row?.show_fawaterek) ? 0 : 1,
                    }
                  );
                  if (disableAgent.data.status == "success") {
                    toast.success("تمت العملية بنجاح");
                    await getAgents();
                  } else {
                    toast.error("فشلت العملية");
                  }
                }}
              >
                {language == "ar" ? "إظهار الفواتير" : "Show Invoices"}
              </button>
            ) : (
              <button
                className="btn btn-danger"
                style={{ whiteSpace: "nowrap" }}
                onClick={async () => {
                  const disableAgent = await axios.post(
                    base_url + "edit_user_show_fatoorah.php",
                    {
                      agent_id: row?.agent_id,
                      show_fawaterek: parseInt(row?.show_fawaterek) ? 0 : 1,
                    }
                  );
                  if (disableAgent.data.status == "success") {
                    toast.success("تمت العملية بنجاح");
                    await getAgents();
                  } else {
                    toast.error("فشلت العملية");
                  }
                }}
              >
                {language == "ar" ? "إخفاء الفواتير" : "Hide Invoices"}
              </button>
            )}
          </span>
        );
      },
    },
  ];

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="add-container">
              <div className="div">
                <ContentNav head={language == "ar" ? "الوكلاء" : "Agents"} />
              </div>
              <div className="pageHeaderLeftSide">
                <span
                  className="tabler-Icons"
                  onClick={() => setShowExport(true)}
                >
                  <ExcelIcon />
                </span>
                <button
                  className="btn btn-primary btn-with-icon"
                  onClick={() => navigate("/doctor/adddoctor")}
                >
                  <img
                    src="https://res.cloudinary.com/duovxefh6/image/upload/v1698247380/insert_mox69q.png"
                    alt=""
                  />
                  <span>{language == "ar" ? "إضافة وكيل" : "Add Agent"}</span>
                </button>
              </div>
            </div>
            <div className="searchContainer">
              <label htmlFor="">ابحث هنا</label>
              <input
                style={{
                  margin: "20px",
                  outline: "none",
                  width: "min(500px, 90%)",
                  borderRadius: "5px",
                  border: ".1px solid",
                  padding: "10px",
                }}
                type="search"
                onChange={(e) => {
                  return e.target.value?.length && e.target.value
                    ? setData(
                        data && data?.length && Array.isArray(data)
                          ? data?.filter(
                              (item) =>
                                item?.login_id
                                  ?.toLowerCase()
                                  ?.includes(e.target.value?.toLowerCase()) ||
                                item?.name
                                  ?.toLowerCase()
                                  ?.includes(e.target.value?.toLowerCase()) ||
                                item?.phone?.includes(
                                  e.target.value?.toLowerCase()
                                ) ||
                                item?.password
                                  ?.toLowerCase()
                                  ?.includes(e.target.value?.toLowerCase())
                            )
                          : []
                      )
                    : getAgents();
                }}
              />
            </div>
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={showMessage}
              toggle={setShowMessage}
              headerTitle={language == "ar" ? "بيانات الوكيل" : "Agent Data"}
              children={
                <p className="message">{showMessage?.item?.description}</p>
              }
            />
            <Modal
              open={showExport}
              toggle={setShowExport}
              headerTitle={
                language == "ar"
                  ? "تصدير البيانات كملف إكسيل"
                  : "Export Data As Excel File"
              }
              children={
                <div className="flex-box">
                  {choosedDocs && choosedDocs?.length ? (
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        exportToCSV(choosedDocs, "cients Data");
                      }}
                    >
                      {language == "ar"
                        ? " تصدير المحدد فقط"
                        : "Export Only Selected"}
                    </button>
                  ) : null}

                  <button
                    className="btn btn-primary"
                    width={"fit-content"}
                    onClick={() => {
                      exportToCSV(data, "cients Data");
                    }}
                  >
                    {language == "ar" ? "تصدير الكل" : "Export all Data"}
                  </button>
                </div>
              }
            />

            <Modal
              open={agent}
              toggle={setAgent}
              headerTitle={
                language == "ar"
                  ? "إيقاف / السماح - للوكيل"
                  : "Stop/Allow Agent"
              }
              children={
                <div className="flex-box">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      disable_enable_agent();
                    }}
                  >
                    {language == "ar" ? "تأكيد" : "Confirm"}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setAgent(false);
                    }}
                  >
                    {language == "ar" ? "إلغاء" : "Cancel"}
                  </button>
                </div>
              }
            />

            <Modal
              open={editAgent}
              toggle={() => {
                setEditAgent(null);
                setImage(null);
                setImageSrc(null);
              }}
              headerTitle={language == "ar" ? "تعديل الوكيل" : "Edit Agent"}
              children={
                <>
                  <div className="inputField">
                    <label htmlFor="">اسم الوكيل</label>
                    <input
                      type="text"
                      value={editAgent?.name}
                      onChange={(e) => {
                        setEditAgent({ ...editAgent, name: e.target?.value });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">رقم تليفون الوكيل</label>
                    <input
                      type="text"
                      value={editAgent?.phone}
                      onChange={(e) => {
                        setEditAgent({ ...editAgent, phone: e.target?.value });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">إيميل لاستقبال الإشعارات</label>
                    <input
                      type="text"
                      value={editAgent?.email}
                      onChange={(e) => {
                        setEditAgent({
                          ...editAgent,
                          email: e.target?.value,
                        });
                      }}
                    />
                  </div>{" "}
                  <div className="inputField">
                    <label htmlFor="">كلمة سر حساب الوكيل</label>
                    <input
                      type="text"
                      value={editAgent?.password}
                      onChange={(e) => {
                        setEditAgent({
                          ...editAgent,
                          password: e.target?.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField type_image">
                    <label htmlFor="image">
                      {language == "ar" ? "صورة الوكيل" : "Agent Image"}
                    </label>
                    <input
                      type="file"
                      id="image"
                      lang="ar"
                      name="image"
                      onChange={changeImage}
                    />
                  </div>
                  {imageSrc ? (
                    <div
                      className="uploaded_image"
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "flex-start",
                        margin: "20px 0",
                      }}
                    >
                      <img
                        src={imageSrc}
                        alt=""
                        width={200}
                        style={{ borderRadius: 5 }}
                      />
                      <span className="btn btn-danger" onClick={deleteImage}>
                        {language == "ar" ? "حذف" : "Delete"}
                      </span>
                    </div>
                  ) : null}
                  <div className="flex-box">
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        return !loading ? editDoctor() : null;
                      }}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <> {language == "ar" ? "تأكيد" : "Confirm"}</>
                      )}
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setEditAgent(false);
                      }}
                    >
                      {language == "ar" ? "إلغاء" : "Cancel"}
                    </button>
                  </div>
                </>
              }
            />
            <Modal
              open={ArchiveKey}
              toggle={setArchiveKey}
              headerTitle={language == "ar" ? "وضع في الأرشيف" : "Archive"}
              children={
                <div className="flex-box">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      ArchiveDoctor();
                    }}
                  >
                    {language == "ar" ? "تأكيد" : "Confirm"}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setArchiveKey(false);
                    }}
                  >
                    {language == "ar" ? "إلغاء" : "Cancel"}
                  </button>
                </div>
              }
            />
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
}

export default Doctor;
