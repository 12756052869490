import { useSelector } from "react-redux";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import "./style.css";
import Table from "../../components/table";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export default function Invoices() {
  const language = useSelector((state) => state.language);
  const [fatoora, setFatoora] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: Infinity });

  useEffect(() => {
    console.log(language);
  }, [language]);

  const headers = [
    { label: "#", dataIndex: "agent_id" },
    {
      label: language === "ar" ? "اسم المنتج" : "Product Name",
      dataIndex: "product_name",
    },
    {
      label: language === "ar" ? "اسم المستخدم" : "User Name",
      dataIndex: "user_name",
    },
    {
      label: language === "ar" ? "رقم الفاتورة" : "Invoice Number",
      dataIndex: "fatoorah_id",
    },
    {
      label: language === "ar" ? "رقم الشخص" : "Phone Number",
      dataIndex: "user_phone",
    },
    {
      label: language === "ar" ? "المبلغ" : "Amount",
      dataIndex: "price",
      type: "children",
      children: ({ row }) => <span>{parseFloat(row?.price)}</span>,
    },
    {
      label: language === "ar" ? "المبلغ الكلي" : "Total Amount",
      dataIndex: "submission_price",
      type: "children",
      children: ({ row }) => <span>{parseFloat(row?.submission_price)}</span>,
    },
    {
      label: language === "ar" ? "مصاريف إدارية" : "Administrative Expenses",
      dataIndex: "administrative_expenses",
      type: "children",
      children: ({ row }) => (
        <span>
          {(
            +row?.submission_price *
            (+row?.administrative_expenses / 100)
          ).toFixed(1)}
        </span>
      ),
    },
    {
      label: language === "ar" ? "صوره العميل" : "Agent Image",
      dataIndex: "agent_image",
      type: "children",
      children: ({ row }) => (
        <img src={row?.agent_info?.agent_image} alt="Agent" />
      ),
    },
    {
      label: language === "ar" ? "اسم العميل" : "Agent Name",
      dataIndex: "agent_name",
      type: "children",
      children: ({ row }) => <p>{row?.agent_info?.agent_name}</p>,
    },
    {
      label: language === "ar" ? "رقم العميل" : "Agent Phone",
      dataIndex: "agent_phone",
      type: "children",
      children: ({ row }) => <p>{row?.agent_info?.agent_phone}</p>,
    },
    {
      label: language === "ar" ? "تاريخ الانشاء" : "Created At",
      dataIndex: "agent_email",
      type: "children",
      children: ({ row }) => <p>{row?.created_at}</p>,
    },
    {
      label: language === "ar" ? "ايميل العميل" : "Agent Email",
      dataIndex: "agent_email",
      type: "children",
      children: ({ row }) => <p>{row?.agent_info?.agent_email}</p>,
    },
    {
      label: language === "ar" ? "الرمز التعريفي" : "Agent Qr",
      dataIndex: "agent_qr_url",
      type: "children",
      children: ({ row }) => (
        <img src={row?.agent_info?.agent_qr_url} alt="Agent QR" />
      ),
    },
  ];

  function handleGetAllData() {
    axios
      .post("https://baseeta-form.com/basita/getFatoorah.php")
      .then((res) => {
        if (res?.data) {
          setFatoora(res.data);
          setFilteredData(res.data); // Initialize with all data
        } else {
          toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
      });
  }

  useEffect(() => {
    handleGetAllData();
  }, []);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    console.log("Search Term:", searchTerm); // Log the search term
    if (!searchTerm && priceRange.min === 0 && priceRange.max === Infinity) {
      setFilteredData(fatoora); // Reset to all data when search is cleared
    } else {
      filterData(searchTerm, priceRange);
    }
  };

  const handlePriceRange = (min, max) => {
    if (!min && !max) {
      // Reset filtered data if both min and max are not defined or set to 0/Infinity
      setFilteredData(fatoora);
    } else {
      // Update price range and apply filter
      setPriceRange({ min, max });
      const searchTerm = document.getElementById("search").value.toLowerCase();
      filterData(searchTerm, { min, max });
    }
  };

  const filterData = (searchTerm, { min, max }) => {
    console.log("Filtering with:", searchTerm, { min, max });
    const filtered = fatoora.filter((item) => {
      const matchesSearch =
        item.product_name?.toLowerCase().includes(searchTerm) ||
        item.user_name?.toLowerCase().includes(searchTerm) ||
        item.user_phone?.includes(searchTerm) ||
        item.fatoorah_id?.toString().includes(searchTerm) ||
        item.agent_info?.agent_name?.toLowerCase().includes(searchTerm) ||
        item.agent_info?.agent_phone?.includes(searchTerm) ||
        item.agent_info?.agent_email?.toLowerCase().includes(searchTerm);
      const withinPriceRange = item.price >= min && item.price <= max;
      return matchesSearch && withinPriceRange;
    });

    console.log("Filtered Data:", filtered);
    setFilteredData(filtered);
  };

  return (
    <div className="h-container con-h">
      <DefaultLayout>
        <div className="childs">
          <div className="header">
            <ContentNav
              head={language === "ar" ? "فواتيرك" : "Your Invoices"}
            />
            <div className="searchContainer">
              <label htmlFor="search">
                {language === "ar" ? "ابحث هنا" : "Search here"}
              </label>
              <input
                id="search"
                style={{
                  margin: "20px",
                  outline: "none",
                  width: "min(500px, 90%)",
                  borderRadius: "5px",
                  border: ".1px solid",
                  padding: "10px",
                }}
                type="search"
                placeholder={
                  language === "ar" ? "ابحث هنا..." : "Search here..."
                }
                onChange={handleSearch}
              />
              {/* <div className="priceFilter">
                <label>
                  {language === "ar" ? "نطاق السعر:" : "Price Range:"}
                </label>
                <input
                  type="number"
                  placeholder={language === "ar" ? "من" : "Min"}
                  onChange={(e) =>
                    handlePriceRange(Number(e.target.value), priceRange.max)
                  }
                  style={{ marginRight: "10px", padding: "5px" }}
                />
                <input
                  type="number"
                  placeholder={language === "ar" ? "إلى" : "Max"}
                  onChange={(e) =>
                    handlePriceRange(priceRange.min, Number(e.target.value))
                  }
                  style={{ padding: "5px" }}
                />
              </div> */}
            </div>
          </div>
        </div>
        <Table headers={headers} body={filteredData} />
      </DefaultLayout>
    </div>
  );
}
